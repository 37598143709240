import React, {useState} from 'react';
import {List, Button, Spin, Typography, message, Modal, Input, Form} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {deleteItem, ItemDetails, updateItem} from '../services/itemService';
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface ItemListProps {
    items: ItemDetails[];
    loading: boolean;
    onReload: () => void;
}

const ItemList: React.FC<ItemListProps> = ({ items, loading, onReload }) => {
    const navigate = useNavigate();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editItem, setEditItem] = useState<ItemDetails | null>(null);

    const handleItemClick = (id: string) => {
        navigate(`/items/${id}`);
    };

    const handleEditClick = (item: ItemDetails) => {
        setEditItem(item);
        setEditModalVisible(true);
    };

    const handleDeleteClick = async (item: ItemDetails) => {
        try {
            const itemForDelete = {
                uuid: item.uuid,
                name: item.name,
                organizationId: item.organizationId
            };
            await deleteItem(itemForDelete);
            message.success('Устройство успешно удалено');
            onReload();
        } catch (error: any) {
            message.error(error.message || 'Ошибка при удалении устройства');
        }
    };

    const handleEditItemSubmit = async (values: any) => {
        if (editItem) {
            try {
                const updatedItem = {
                    uuid: editItem.uuid,
                    name: values.name,
                    serialNumber: values.serialNumber,
                    organizationId: editItem.organizationId
                };
                await updateItem(updatedItem);
                message.success('Устройство успешно обновлено');
                setEditModalVisible(false);
                setEditItem(null);
                onReload();
            } catch (error: any) {
                message.error(error.message || 'Ошибка при обновлении устройства');
            }
        }
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
        setEditItem(null);
    };

    return (
        <>
            {loading ? (
                <Spin />
            ) : (
                <List
                    bordered
                    dataSource={items}
                    renderItem={(item) => (
                        <List.Item
                            key={item.uuid}
                            style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}}
                        >
                            <div onClick={() => handleItemClick(item.uuid)} style={{flex: 1}}>
                                <Text strong>{item.name}</Text>
                                <br/>
                                <Text type="secondary">Серийный номер: {item.serialNumber ? item.serialNumber : "отсутствует"}</Text>
                                <br/>
                                <Text type="secondary">Организация: {item.organizationName}</Text>
                                <br/>
                                <Text type="secondary">Создано: {new Date(item.createdAt).toLocaleString()}</Text>
                            </div>
                            <div style={{display: 'flex', gap: '8px'}}>
                            <Button
                                    icon={<EditOutlined/>}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditClick(item);
                                    }}
                                />
                                <Button
                                    icon={<DeleteOutlined/>}
                                    danger
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteClick(item);
                                    }}
                                />
                            </div>
                        </List.Item>
                    )}
                />
            )}
            <Modal
                visible={editModalVisible}
                title="Редактировать устройство"
                onCancel={handleEditModalCancel}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={handleEditItemSubmit}
                    initialValues={{ name: editItem?.name, serialNumber: editItem?.serialNumber }}
                >
                    <Form.Item
                        name="name"
                        label="Имя устройства"
                        rules={[{ required: true, message: 'Введите имя' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="serialNumber"
                        label="Серийный номер"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                        <Button onClick={handleEditModalCancel} style={{ marginLeft: '8px' }}>
                            Отмена
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ItemList;
