import api from './api';

export interface Organization {
    uuid: string;
    name: string;
    contactEmail: string;
    createdAt: string;
}

export const handleAddOrganization = async (name: string, contactEmail: string): Promise<void> => {
    try {
        await api.post('/organizations', { name, contactEmail });
    } catch (error) {
        console.error('Failed to add organization:', error);
        throw error;
    }
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
    try {
        const response = await api.get('/organizations');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch organizations:', error);
        throw error;
    }
};

export const updateOrganization = async (id: string, name: string, contactEmail: string): Promise<void> => {
    await api.put('/organizations', { id, name, contactEmail });
}

export const deleteOrganization = async (id: string): Promise<void> => {
    await api.delete('/organizations', { data: {id} });
}
