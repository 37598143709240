import React, {useEffect, useState} from 'react';
import {Table, Tag, Spin, message, Space, Popconfirm, Button} from 'antd';
import { closeAlert, deleteAlert } from '../services/alerts';
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import '../styles/alertsDashboard.css';
import {TablePaginationConfig} from "antd/lib/table";

interface Alert {
    id: number;
    name: string;
    resource: string;
    event: string;
    open: boolean;
    severity: 'OK' | 'WARN' | 'ERROR';
    organizationId: string;
    duplicateCount: number;
    lastReceivedAt: string;
    itemName?: string;
}

interface PaginationProps {
    currentPage: number;
    pageSize: number;
    total: number;
}

interface AlertsDashboardProps {
    alerts: Alert[];
    loading: boolean;
    pagination: PaginationProps;
    onChange: (pagination: TablePaginationConfig) => void;
}

const AlertsDashboard: React.FC<AlertsDashboardProps> = ({ alerts, loading, pagination, onChange }) => {
    const [alertList, setAlertList] = useState<Alert[]>(alerts);
    const [actionLoading, setActionLoading] = useState<Record<number, boolean>>({});

    useEffect(() => {
        setAlertList(alerts);
    }, [alerts]);

    const handleClose = async (id: number) => {
        setActionLoading(prev => ({ ...prev, [id]: true }));
        try {
            await closeAlert(id);
            message.success('Алерт успешно закрыт');
            setAlertList(prev =>
                prev.map(alert =>
                    alert.id === id ? { ...alert, open: false } : alert
                )
            );
        } catch (error) {
            console.error('Не удалось закрыть алерт:', error);
            message.error('Не удалось закрыть алерт');
        } finally {
            setActionLoading(prev => ({ ...prev, [id]: false }));
        }
    };

    const handleDelete = async (id: number) => {
        setActionLoading(prev => ({ ...prev, [id]: true }));
        try {
            await deleteAlert(id);
            message.success('Алерт успешно удалён');
            setAlertList(prev => prev.filter(alert => alert.id !== id));
        } catch (error) {
            console.error('Не удалось удалить алерт:', error);
            message.error('Не удалось удалить алерт');
        } finally {
            setActionLoading(prev => ({ ...prev, [id]: false }));
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
        },
        {
            title: 'Open',
            dataIndex: 'open',
            key: 'open',
            render: (open: boolean) => (open ? 'Yes' : 'No'),
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            render: (severity: string) => {
                let color = 'green';
                if (severity === 'WARN') {
                    color = 'yellow';
                } else if (severity === 'ERROR') {
                    color = 'red';
                }
                return <Tag color={color}>{severity}</Tag>;
            },
        },
        {
            title: 'Item',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Duplicate Count',
            dataIndex: 'duplicateCount',
            key: 'duplicateCount',
        },
        {
            title: 'Last Received At',
            dataIndex: 'lastReceivedAt',
            key: 'lastReceivedAt',
            render: (date: string) => new Date(date).toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: Alert) => (
                <Space size="middle">
                    <Popconfirm
                        title="Вы уверены, что хотите закрыть алерт?"
                        onConfirm={() => handleClose(record.id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            type="text"
                            icon={<CloseOutlined />}
                            danger
                            loading={actionLoading[record.id]}
                            aria-label={`Close alert ${record.name}`}
                        />
                    </Popconfirm>
                    <Popconfirm
                        title="Вы уверены, что хотите удалить алерт?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            danger
                            loading={actionLoading[record.id]}
                            aria-label={`Delete alert ${record.name}`}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const getRowClassName = (record: Alert) => {
        return !record.open ? 'closed-alert-row' : '';
    };

    return loading ? <Spin /> : (
        <Table
            columns={columns}
            dataSource={alertList}
            rowKey="id"
            rowClassName={getRowClassName}
            pagination={{
                current: pagination.currentPage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} записей`,
            }}
            onChange={onChange}
        />
    );
};

export default AlertsDashboard;
