import api from './api';

export interface License {
    uuid: string;
    organizationId: string;
    organizationName: string;
    expirationDate: string;
}

export interface LicenseExpiration {
    id: string;
    expirationDate: string;
}

export const handleAddLicense = async (organizationId: string, expirationDate: string): Promise<void> => {
    await api.post('/licenses', { organizationId, expirationDate });
};

export const fetchLicenses = async (): Promise<License[]> => {
    const response = await api.get('/licenses');
    return response.data;
};

export const updateLicense = async (licenseId: string, expirationDate: string): Promise<void> => {
    await api.put('/licenses', { licenseId, expirationDate });
}

export const deleteLicense = async (licenseId: string): Promise<void> => {
    await api.delete('/licenses', { data: {licenseId} });
}