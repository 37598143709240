import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { handleAddOrganization } from '../services/organizations';

interface OrganizationFormProps {
    onOrganizationAdded: () => void;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({ onOrganizationAdded }) => {
    const [orgName, setOrgName] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        setLoading(true);
        try {
            await handleAddOrganization(orgName, orgEmail);
            setOrgName('');
            setOrgEmail('');
            message.success('Organization added successfully!');
            onOrganizationAdded();
        } catch (error) {
            message.error('Failed to add organization.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onFinish={onSubmit} layout="inline">
            <Form.Item>
                <Input
                    placeholder="Название организации"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    required
                />
            </Form.Item>
            <Form.Item>
                <Input
                    placeholder="Контактный email"
                    value={orgEmail}
                    onChange={(e) => setOrgEmail(e.target.value)}
                    required
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Создать
                </Button>
            </Form.Item>
        </Form>
    );
};

export default OrganizationForm;
