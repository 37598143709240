import React, { useState, useEffect } from 'react';
import {Typography, Divider, message, Form, Modal, DatePicker, Button, Input} from 'antd';
import OrganizationForm from '../components/organizationForm';
import LicenseForm from '../components/licenseForm';
import OrganizationList from '../components/organizationList';
import LicenseList from '../components/licenseList';
import {
    fetchLicenses,
    License,
    deleteLicense,
    updateLicense,
} from '../services/licenses';
import {
    deleteOrganization,
    fetchOrganizations,
    Organization, updateOrganization,
} from '../services/organizations';
import CustomerForm from "../components/customerForm";
import CustomerList from "../components/customerList";
import {User} from "../contexts/userContext";
import {fetchCustomers} from "../services/customerService";
import EditCustomerModal from "../components/edtiCustomerModal";

const { Title } = Typography;

const ManagementPage: React.FC = () => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [licenses, setLicenses] = useState<License[]>([]);
    const [customers, setCustomers] = useState<User[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadingLicenses, setLoadingLicenses] = useState(false);
    const [loadingCustomers, setLoadingCustomers] = useState(false);

    const [isOrgEditModalVisible, setIsOrgEditModalVisible] = useState(false);
    const [editingOrg, setEditingOrg] = useState<Organization | null>(null);

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const loadOrganizations = async () => {
        setLoadingOrganizations(true);
        try {
            const data = await fetchOrganizations();
            setOrganizations(data);
        } catch (error) {
            message.error('Не удалось загрузить организации.');
        } finally {
            setLoadingOrganizations(false);
        }
    };

    const loadLicenses = async () => {
        setLoadingLicenses(true);
        try {
            const data = await fetchLicenses();
            setLicenses(data);
        } catch (error) {
            message.error('Не удалось загрузить лицензии.');
        } finally {
            setLoadingLicenses(false);
        }
    };

    const loadCustomers = async () => {
        setLoadingCustomers(true);
        try {
            const data = await fetchCustomers();
            setCustomers(data);
        } catch (error: any) {
            message.error('Не удалось загрузить пользователей.');
        } finally {
            setLoadingCustomers(false);
        }
    };

    useEffect(() => {
        loadOrganizations();
        loadLicenses();
        loadCustomers();
    }, []);

    const handleOrganizationAdded = () => {
        loadOrganizations();
    };

    const handleEdit = (user: User) => {
        setSelectedUser(user);
        setIsEditModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
        setSelectedUser(null);
    };

    const handleEditModalSuccess = () => {
        setIsEditModalVisible(false);
        setSelectedUser(null);
        loadCustomers();
    };

    const handleUpdateLicense = (license: License) => {
        Modal.confirm({
            title: 'Изменить дату окончания лицензии',
            content: (
                <Form
                    layout="vertical"
                    onFinish={async (values) => {
                        try {
                            console.log(values);
                            await updateLicense(license.uuid, values.expirationDate.toISOString());
                            message.success('Дата окончания лицензии успешно обновлена.');
                            loadLicenses();
                            Modal.destroyAll();
                        } catch (error) {
                            console.log(values);
                        }
                    }}
                >
                    <Form.Item
                        name="expirationDate"
                        label="Дата окончания"
                        rules={[
                            { required: true, message: 'Пожалуйста, выберите дату окончания.' },
                        ]}
                    >
                        <DatePicker
                            placeholder="Дата окончания"
                            style={{ width: 200 }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                        <Button onClick={() => Modal.destroyAll()} style={{ marginLeft: '8px' }}>
                            Отмена
                        </Button>
                    </Form.Item>
                </Form>
            ),
            okButtonProps: { style: { display: 'none' } },
            cancelButtonProps: { style: { display: 'none' } },
        });
    };

    const handleDeleteLicense = async (licenseId: string) => {
        try {
            await deleteLicense(licenseId);
            message.success('Лицензия успешно удалена.');
            loadLicenses();
        } catch (error) {
            message.error('Не удалось удалить лицензию.');
        }
    };

    const handleDeleteOrganization = async (orgId: string) => {
        try {
            await deleteOrganization(orgId);
            message.success('Организация успешно удалена.');
            loadOrganizations();
        } catch (error) {
            message.error('Не удалось удалить организацию.');
        }
    };

    const handleEditOrganization = (org: Organization) => {
        setEditingOrg(org);
        setIsOrgEditModalVisible(true);
    };

    const handleUpdateOrganizationSubmit = async (values: { name: string, contactEmail: string }) => {
        if (editingOrg) {
            try {
                await updateOrganization(editingOrg.uuid, values.name, values.contactEmail);
                message.success('Организация успешно обновлена.');
                loadOrganizations();
                setIsOrgEditModalVisible(false);
                setEditingOrg(null);
            } catch (error: any) {
                message.error('Не удалось обновить организацию.');
            }
        }
    };

    const handleOrgEditModalCancel = () => {
        setIsOrgEditModalVisible(false);
        setEditingOrg(null);
    };

    return (
        <>
            <Title level={3}>Добавить организацию</Title>
            <OrganizationForm onOrganizationAdded={handleOrganizationAdded} />
            <Divider />
            <Title level={3} style={{ marginTop: '20px' }}>Организации</Title>
            <OrganizationList
                organizations={organizations}
                loading={loadingOrganizations}
                onReload={loadOrganizations}
                onEdit={handleEditOrganization}
                onDelete={handleDeleteOrganization}
            />
            <Divider />
            <Title level={3} style={{ marginTop: '20px' }}>Создать пользователя</Title>
            <CustomerForm
                organizations={organizations}
                refreshCustomers={loadCustomers}
            />
            <Divider />
            <Title level={3} style={{ marginTop: '20px' }}>Пользователи</Title>
            <CustomerList
                customers={customers}
                loading={loadingCustomers}
                onReload={loadCustomers}
                onEdit={handleEdit}
            />
            <Divider />
            <Title level={3} style={{ marginTop: '20px' }}>Добавить лицензию</Title>
            <LicenseForm
                organizations={organizations}
                refreshLicenses={loadLicenses}
            />
            <Divider />
            <Title level={3} style={{ marginTop: '20px' }}>Лицензии</Title>
            <LicenseList
                licenses={licenses}
                loading={loadingLicenses}
                onReload={loadLicenses}
                handleDelete={handleDeleteLicense}
                handleUpdate={handleUpdateLicense}
            />

            <EditCustomerModal
                visible={isEditModalVisible}
                onCancel={handleEditModalCancel}
                onSuccess={handleEditModalSuccess}
                user={selectedUser}
                organizations={organizations}
            />

            <Modal
                visible={isOrgEditModalVisible}
                title="Редактировать организацию"
                onCancel={handleOrgEditModalCancel}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={handleUpdateOrganizationSubmit}
                    initialValues={{ name: editingOrg?.name, contactEmail: editingOrg?.contactEmail }}
                >
                    <Form.Item
                        name="name"
                        label="Название организации"
                        rules={[{ required: true, message: 'Введите название' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="contactEmail"
                        label="Контактный email"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                        <Button onClick={handleOrgEditModalCancel} style={{ marginLeft: '8px' }}>
                            Отмена
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ManagementPage;
