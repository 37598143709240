import React, { useState } from 'react';
import { Form, Input, Button, Select, message, Spin } from 'antd';
import {createItem, Item } from '../services/itemService';
import { Organization } from '../services/organizations';

const { Option } = Select;

interface ItemFormProps {
    organizations: Organization[];
    loadingOrganizations: boolean;
    onItemAdded: () => void;
}

const ItemForm: React.FC<ItemFormProps> = ({
                                               organizations,
                                               loadingOrganizations,
                                               onItemAdded
                                           }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const newItem: Partial<Item> = {
                name: values.name,
                serialNumber: values.serialNumber,
                organizationId: values.organizationId,
            };
            await createItem(newItem as any);
            message.success('Устройство успешно создан!');
            form.resetFields();
            onItemAdded();
        } catch (error) {
            message.error('Не удалось создать устройство.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Пожалуйста, введите название устройства' }]}
            >
                <Input placeholder="Название устройства" />
            </Form.Item>

            <Form.Item
                label="Серийный номер"
                name="serialNumber"
            >
                <Input placeholder="Серийный номер" />
            </Form.Item>

            <Form.Item
                label="Организация"
                name="organizationId"
                rules={[{ required: true, message: 'Пожалуйста, выберите организацию' }]}
            >
                {loadingOrganizations ? (
                    <Spin />
                ) : (
                    <Select placeholder="Выберите организацию">
                        {organizations.map(org => (
                            <Option key={org.uuid} value={org.uuid}>
                                {org.name}
                            </Option>
                        ))}
                    </Select>
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                    Создать
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ItemForm;
