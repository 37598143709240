import React, {useState, useEffect, useContext } from 'react';
import {Typography, message, Form, Select, Checkbox, Input, Button, Spin} from 'antd';
import {fetchAlerts, Alert, AlertFilterRequestDto, FetchAlertsResponse} from '../services/alerts';
import AlertsDashboard from "../components/alertsDashboard";
import {UserContext} from "../contexts/userContext";
import {fetchOrganizations, Organization} from "../services/organizations";
import { TablePaginationConfig } from 'antd/lib/table';

const { Title } = Typography;
const { Option } = Select;

const Dashboard: React.FC = () => {
    const user = useContext(UserContext);

    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingAlerts, setLoadingAlerts] = useState<boolean>(false);
    const [loadingOrganizations, setLoadingOrganizations] = useState<boolean>(false);
    const [filters, setFilters] = useState<AlertFilterRequestDto>({});

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    const loadAlerts = async () => {
        setLoadingAlerts(true);
        try {
            const limit = pageSize;
            const offset = (currentPage - 1) * pageSize;
            const filtersWithPagination: AlertFilterRequestDto = { ...filters, limit, offset };
            const response: FetchAlertsResponse = await fetchAlerts(filtersWithPagination);
            setAlerts(response.alerts);
            setTotal(response.total);
        } catch (error) {
            message.error('Не удалось загрузить уведомления.');
        } finally {
            setLoadingAlerts(false);
        }
    };

    const loadOrganizations = async () => {
        setLoadingOrganizations(true);
        try {
            const data = await fetchOrganizations();
            setOrganizations(data);
        } catch (error) {
            message.error('Не удалось загрузить организации.');
        } finally {
            setLoadingOrganizations(false);
        }
    };

    useEffect(() => {
        loadAlerts();
    }, [filters, currentPage, pageSize]);

    useEffect(() => {
        if (user?.role === 'ROLE_ADMIN') {
            loadOrganizations();
        }
    }, [user]);

    const handleFormSubmit = (values: AlertFilterRequestDto) => {
        setFilters(values);
        setCurrentPage(1);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    return (
        <>
            <Title level={3}>Уведомления</Title>
            <Form layout="inline" onFinish={handleFormSubmit} style={{ marginBottom: '20px' }}>
                {user?.role === 'ROLE_ADMIN' && (
                    <Form.Item
                        label="Организация"
                        name="organizationId"
                        rules={[{ required: false, message: 'Пожалуйста, выберите организацию' }]}
                    >
                        {loadingOrganizations ? (
                            <Spin />
                        ) : (
                            <Select placeholder="Выберите организацию">
                                <Option value={null}>Все</Option>
                                {organizations.map(org => (
                                    <Option key={org.uuid} value={org.uuid}>
                                        {org.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                )}
                <Form.Item name="name" label="Name">
                    <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item name="resource" label="Resource">
                    <Input placeholder="Enter resource" />
                </Form.Item>
                <Form.Item name="open" label="Open">
                    <Select allowClear placeholder="Выберите статус" style={{ width: 120 }}>
                        <Option value={null}>Все</Option>
                        <Option value={true}>Открытые</Option>
                        <Option value={false}>Закрытые</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="severity" label="Severity">
                    <Select allowClear placeholder="Select severity">
                        <Option value={null}>Все</Option>
                        <Option value="OK">OK</Option>
                        <Option value="WARN">WARN</Option>
                        <Option value="ERROR">ERROR</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="expired" valuePropName="checked">
                    <Checkbox>Expired</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Apply Filters
                    </Button>
                </Form.Item>
            </Form>
            <AlertsDashboard
                alerts={alerts}
                loading={loadingAlerts}
                pagination={{ currentPage, pageSize, total }}
                onChange={handleTableChange}
            />
        </>
    );
};

export default Dashboard;
